/** @jsx jsx */
import { graphql } from 'gatsby';
import About from '../components/about';

export const query = graphql`
  query {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      keywords
    }
    about: sanityAbout {
      _rawEnglishDescription(resolveReferences: { maxDepth: 5 })
      _rawKoreanDescription(resolveReferences: { maxDepth: 5 })
      mainImage {
        crop {
          _key
          _type
          bottom
          left
          right
          top
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
        asset {
          _id
        }
      }
      koreanTitle
      englishTitle
    }
  }
`;

export default About;
