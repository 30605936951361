/** @jsx jsx */
import { useEffect } from 'react';
import { jsx, Text } from 'theme-ui';
import { graphql, Link, navigate } from 'gatsby';
import GraphQLErrorList from './graphql-error-list';
import SEO from './seo';
import Layout from './layout';
import NavBar from './nav-bar';
import Footer from './footer';
import Titles from './titles';
import { useMedia } from '../hooks';
import ResponsiveMainImage from './responsive-main-image';
import PortableText from './body-portable-text';
import BreadcrumbTrigger from './breadcrumb-trigger';
import SubscribeAndMadeBy from './subscribe-and-made-by';
import { toPlainText, isKoreanBrowser } from '../lib/helpers';
import { navBarHeightsInArray } from './const';

function getMainImageAspectRatio(isMobile, isTablet) {
  if (isMobile) {
    return 3 / 2;
  }
  if (isTablet) {
    return 1;
  }
  return 2 / 3;
}

const Breadcrumb = ({ isKorean, koreanTitle, englishTitle }) => (
  <p
    sx={{
      '& span': {
        fontSize: [14, 16],
        lineHeight: '1em',
      },
    }}
  >
    <span sx={{ fontFamily: 'breadcrumb1' }}>About</span>
    <span sx={{ fontFamily: 'breadcrumb1', mx: [6, 8] }}>/</span>
    <span sx={{ fontFamily: 'breadcrumb2' }}>
      {isKorean ? koreanTitle : englishTitle}
    </span>
  </p>
);

export default function AboutPage({
  data,
  path,
  errors,
  pageContext: { lang },
  renderBreadcrumb = undefined,
  subPath = 'about', // either "about" or "coming-soon"
  linkOnLogo = true,
  showMenu = true,
  redirectForKoreans = false,
}) {
  const koUrl = `/${subPath}/ko`;
  const enUrl = `/${subPath}/en`;
  const { isMobile, isTablet } = useMedia();
  const aspectRatio = getMainImageAspectRatio(isMobile, isTablet);

  useEffect(() => {
    if (redirectForKoreans && path === '/' && isKoreanBrowser()) {
      navigate(koUrl, { replace: true });
    }
  }, [path, redirectForKoreans, koUrl]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { site, about } = data || {};
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const {
    englishTitle,
    koreanTitle,
    mainImage,
    _rawEnglishDescription,
    _rawKoreanDescription,
  } = about;

  const isKorean = lang === 'ko';
  const otherLang = isKorean ? 'en' : 'ko';
  const title = isKorean ? koreanTitle : englishTitle;
  const subtitle = isKorean ? englishTitle : koreanTitle;
  const _rawDescription = isKorean
    ? _rawKoreanDescription
    : _rawEnglishDescription;

  if (!renderBreadcrumb) {
    // eslint-disable-next-line no-param-reassign
    renderBreadcrumb = () => (
      <Breadcrumb
        isKorean={isKorean}
        koreanTitle={koreanTitle}
        englishTitle={englishTitle}
      />
    );
  }

  const imageTopMargins = [42, 58, 60];
  const imageBottomMargins = [32, 36, 80];

  return (
    <Layout lang={lang}>
      <SEO
        title={title}
        description={toPlainText(_rawDescription)}
        keywords={site.keywords}
        path={path}
        lang={lang}
        image={mainImage}
      />
      <NavBar
        lang={lang}
        renderBreadcrumb={renderBreadcrumb}
        linkOnLogo={linkOnLogo}
        showMenu={showMenu}
        koUrl={koUrl}
        enUrl={enUrl}
      />
      <div sx={{ pt: [96, 136, 160] }}></div>
      <Titles
        sx={{
          mt: navBarHeightsInArray,
        }}
        title={title}
        subtitle={subtitle}
        subtitleUrl={`/${subPath}/${otherLang}`}
      />
      <BreadcrumbTrigger level="lvl1" sx={{ mt: imageTopMargins }} />
      <ResponsiveMainImage
        node={mainImage}
        aspectRatio={aspectRatio}
        sx={{ mt: imageTopMargins, mb: imageBottomMargins }}
      />
      <BreadcrumbTrigger
        level="lvl2"
        sx={{ mt: imageBottomMargins.map(m => m * -1) }}
      />
      <PortableText blocks={_rawDescription} />
      <SubscribeAndMadeBy lang={lang} />
      <Footer sx={{ mt: 32 }} lang={lang} koUrl={koUrl} enUrl={enUrl} />
    </Layout>
  );
}
